<template>
  <LcHeader
    headline-class="flex items-center justify-center text-h3"
    @left-button-click="navigateBack"
  >
    <template #headline>
      {{ title }}
    </template>

    <slot />
  </LcHeader>
</template>

<script lang="ts" setup>
defineProps<{ title: string }>()

const { navigateBack } = useRouterUtils()
</script>
